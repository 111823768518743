* {
  --primary-color: #0A70A8; }

* {
  font-family: Arial, Helvetica, sans-serif; }

body {
  background: #F5F5F5;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.fo-stack {
  position: relative; }
  .fo-stack > *:first-child {
    position: absolute; }

header {
  display: flex;
  align-items: center;
  background: var(--primary-color);
  color: #FFF;
  padding: 15px;
  position: sticky;
  top: 0;
  z-index: 9;
  font-size: 1.2em; }

header .logo {
  max-width: 90%;
  max-height: 8vh;
  display: block; }

header .salle {
  margin-left: auto;
  margin-right: 10px;
  font-size: 1em;
  background: var(--primary-color);
  color: #FFF;
  border: 0;
  text-align: right; }

header .logo:last-child {
  margin: auto; }

#container {
  padding: 10px; }
